/*
 * From https://unindented.org/articles/trigonometry-in-sass/
*/
/*
 * Color variables
 */
.triangle {
  position: relative;
  display: flex; }

.triangle .side {
  position: absolute; }

/*
 * Color variables
 */
section {
  margin-top: 10vh; }

section .section-title {
  margin: 0; }

section .section-content {
  margin-top: 5vh; }

/*
 * Color variables
 */
.transition {
  height: 60vh;
  width: 0;
  border-left: 4px solid #A6B19C;
  margin-left: calc(50% - 2px);
  border-image: linear-gradient(to bottom, rgba(166, 177, 156, 0), #A6B19C 25%, #A6B19C 65%, #A6B19C) 1 100%; }

@media (min-height: 400px) and (max-height: 500px) {
  .transition {
    margin-top: -5vh; } }

@media (min-height: 500px) and (max-height: 900px) {
  .transition {
    margin-top: -10vh; } }

@media (min-height: 900px) {
  .transition {
    margin-top: -20vh; } }

/*
 * Color variables
 */
.timeline {
  list-style: none;
  padding-top: 0.5em;
  padding-left: 50%;
  padding-right: 50%;
  display: flex;
  flex-flow: column wrap;
  margin: 0 auto;
  padding-bottom: 2em;
  position: relative;
  width: 100%;
  /* Vertical line when timeline is horizontal */ }

.timeline, .timeline * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

@media (min-width: 400px) and (max-width: 800px) {
  .timeline {
    width: 400px; } }

@media (min-width: 800px) {
  .timeline {
    width: 800px; } }

@media (min-width: 800px) {
  .timeline:before {
    content: "";
    position: absolute;
    top: 0.5em;
    bottom: 0;
    left: calc(50% - 1.5px);
    border-left: 2px solid #2D3B20; } }
